progress {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  border: none;
  background: transparent;
}

progress::-webkit-progress-bar {
  background: transparent;
}

progress::-webkit-progress-value {
  background-color: #1d4ed8;
  /* background: linear-gradient(0.25turn, #22d3ee, #1d4ed8); */
}
